<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <ARow :gutter="[8, 8]">
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterProvince
                    style="width:100%;"
                    :mode="null"
                    v-model:value="state.params.province"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterKabupatenKota
                    style="width:100%;"
                    :mode="null"
                    v-model:provinsi="state.params.province"
                    v-model:value="state.params.city"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterToko
                    style="width:100%;"
                    :mode="null"
                    v-model:value="state.params.customerCode"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <AButton
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </ACol>
        </ARow>

        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12"></div>
            <div class="col-lg-6 col-md-12 text-right">
                <DownloadExcel
                    :url="state.endpoint"
                    :params="queryParams()"
                    @errors="errorMessage"/>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <TableStandard
                v-if="!state.isFetching"
                service="atsalesman"
                per_page_name="perPage"
                :url="state.endpoint"
                :columns="state.columns"
                :params="queryParams()"
                @errors="errorMessage"/>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, nextTick } from 'vue'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import FilterToko from '@/components/filter/FilterToko'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import TableStandard from '@/components/Molecules/TableStandard'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/queryparams'

export default defineComponent({
    components: {
        FilterProvince,
        FilterKabupatenKota,
        FilterToko,
        DownloadExcel,
        TableStandard,
    },
    setup() {
        const errorMessage = ref()
        const state = reactive({
            columns: [
                {
                    title: 'NO',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'Kode Toko',
                    dataIndex: 'customerCode',
                },
                {
                    title: 'Nama Toko',
                    dataIndex: 'customerName',
                },
                {
                    title: 'Owner Name',
                    dataIndex: 'ownerName',
                },
                {
                    title: 'Sell Capacity',
                    dataIndex: 'sellCapacity',
                },
                {
                    title: 'Warehouse Capacity',
                    dataIndex: 'warehouseCapacity',
                },
                {
                    title: 'Latitude',
                    dataIndex: 'latitude',
                },
                {
                    title: 'Longitude',
                    dataIndex: 'longitude',
                },
                {
                    title: 'Provinsi',
                    dataIndex: 'province',
                },
                {
                    title: 'Kabupaten / Kota',
                    dataIndex: 'city',
                },
                {
                    title: 'Kode Pos',
                    dataIndex: 'postalCode',
                },
                {
                    title: 'Alamat',
                    dataIndex: 'address',
                },
                {
                    title: 'Status Data ',
                    dataIndex: 'status',
                },
                {
                    title: 'Foto',
                    dataIndex: 'photo',
                },
                {
                    title: 'Tanggal Updated',
                    dataIndex: 'updatedAt',
                },
                {
                    title: 'Update Oleh',
                    dataIndex: 'updatedBy',
                },
            ],
            endpoint:'/external-api/survey/get-customer-informations',
            isFetching: false,
            params: extractQueryParams({
                q: '',
                province: null,
                city: null,
                customerCode: null,
                page: 1,
                perPage: 10,
            }),
        })

        const queryParams = () => {
            let params = state.params

            return updateUrlWithQuery(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true
            
            nextTick().then(() => {
                state.isFetching = false
            })
        }

        return {
            state,
            errorMessage,
            queryParams,
            fetchDataList,
        }
    },
})
</script>
